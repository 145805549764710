<template>
    <div id="activity">
        <h2>{{header}}</h2>
        <div class="activity-items">
            <div v-for="(item, index) in $root.TEXT.activity" :key="index" class="activity-item">
                <img :src="item.image">
                <h3>{{item.title}}</h3>
                <p>{{item.text}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default ({
        props: ['header'],
    })
</script>
