const RU = {
    menu: [
        {
            name: 'Сферы деятельности',
            link: '#activity',
            click: false
        },
        {
            name: 'Проекты',
            link: '#projects',
            click: false
        },
        {
            name: 'Компетенции и квалификация',
            link: '#services',
            click: false
        },
        {
            name: 'Контакты',
            link: '#contacts',
            click: false
        },
        {
            name: 'Войти',
            link: '/personal/',
            click: false
        },

    ],
    about: {
        text: 'ARTech - российская IT-компания, оказывающая услуги по разработке системного и прикладного ПО с использованием технологий компьютерного зрения, дополненной реальности, нейросетевых алгоритмов.'
    },
    activity: [
        {
            title: 'Пользовательское',
            text: 'Декстопное и мобильное ПО для создания авторского контента',
            image: './images/people.png'
        },
        {
            title: 'Промышленное',
            text: 'Системное и прикладное ПО для управления предприятиями',
            image: './images/factory.png'
        },
        {
            title: 'Развлечения',
            text: 'Кросплатформенные игры, в том числе с использованием технологии дополненной реальности',
            image: './images/gamepad.png'
        },
        {
            title: 'Навигация',
            text: 'Мобильное ПО для навигации в и вне помещений без использования систем геопозиционирования',
            image: './images/map2.png'
        },
        {
            title: 'Безопасность',
            text: 'Сервисные службы и АПК персонализированного доступа и верификации',
            image: './images/security.png'
        },
    ],
    projects: {
        buttontext: 'Узнать подробности',
        items: [
            {
                id: 'oko',
                subtitle: 'Продукт цифровой экосистемы oKo',
                title: 'oKo-face',
                text: 'oKo-face – система персонализированного бесключевого доступа человека на определенную территорию (предприятие; отдельное помещение предприятия; парковка; дом; гараж и т.д.)',
                images: false,
                link: 'https://ar-tech.tech/okoface/',
                linktext: 'Перейти на сайт продукта',
                html: '<img class="phone" src="/images/oko/1.png"/>'
            },
            {
                id: 'oko-control',
                subtitle: 'Продукт цифровой экосистемы oKo',
                title: 'oKo-control',
                text: 'oKo-control – программное обеспечение для визуального контроля продукции широкого спектра.',
                images: false,
                link: 'https://ar-tech.tech/okocontrol',
                linktext: 'Перейти на сайт продукта',
                html: '<img class="phone" src="/images/oko/1.png"/>'
            },
            {
                id: 'peekid',
                title: 'PEEK ID',
                subtitle: 'Продукт цифровой экосистемы oKo',
                text: "PEEK ID – система предоставления доступа к компьютеру с помощью распознавания лица\n\n\nPEEK ID распознает, находится ли пользователь в данный момент перед компьютером и блокирует доступ если пользователь отошел или не авторизован.",
                images: false,
                link: 'http://peekid.org/',
                linktext: 'Перейти на сайт продукта',
                html: '<img class="phone" src="/images/peekid/1.png"/>'
            },
            {
                id: 'okoshop',
                title: 'oKo-shop',
                subtitle: 'Продукт цифровой экосистемы oKo',
                text: 'oKo-shop - это многофункциональный сервис для вашего сайта, позволяющий продавать товар с помощью персональных прямых трансляций по запросу покупателей.',
                images: false,
                link: 'https://ar-tech.tech/okoshop/',
                linktext: 'Перейти на сайт продукта',
                html: '<img class="phone" src="/images/okoshop/1.png"/>'
            },
            {
                id: 'followme',
                title: 'Follow Me',
                subtitle: 'Навигационный продукт',
                text: 'Follow Me - навигационное мобильное приложение для построения маршрутов с помощью технологии дополненной реальности. Приложение не использует мобильный интернет, Wi-Fi, GPS. Построенными маршрутами можно легко делиться с друзьями и коллегами.',
                images: [
                    '/images/followme/1.jpg',
                    '/images/followme/2.jpg',
                    '/images/followme/3.jpg'
                ],
                link: 'http://8path.tech/',
                linktext: 'Перейти на сайт продукта',
                html: '<img class="phone" src="/images/followme/1.png"/>'
            },
            /*{
                id: 'brightalphabet',
                title: 'Bright Alphabet',
                subtitle: 'Образовательный продукт',
                text: 'Bright Alphabet - мобильное образовательное приложение для детей дошкольного возраста с технологией дополненной реальности. Позволяет в интерактивной форме изучать иностранные алфавиты.',
                images: [
                    '/images/brightalphabet/1.jpg',
                ],
                link: 'https://ar-tech.tech/brightalphabet/',
                linktext: 'Перейти на сайт продукта',
                html: '<img class="phone" src="/images/brightalphabet/1.png"/>'
            },*/
        ]
    },
    services: [
        {
            title: 'Высококвалифицированная команда разработчиков',
            text: 'В ARTech работают опытные специалисты в области разработки аппаратно-программных комплексов, AR/MR/VR решений, автономных интеллектуальных РТК и устройств',
            image: './images/people.png'
        },
        /*{
            title: 'Разрабатываем лучшие решения',
            text: 'Наши проекты отмечены наградами международного уровня.',
            image: './images/factory.png'
        },*/
        {
            title: 'Технологии и инструменты разработки',
            text: '<ul><li>Языки программирования: Аcсемблер, C++/C#, GoLang, Java/Kotlin, JavaScript, Objective-C, Perl, PHP, Python, Ruby, SQL, Swift</li>'+
            '<li>САПР: AutoCAD, SolidWorks, Siemens NX, NX Unigraphics, Компас-3D</li>'+
            '<li>3D-движки: Unity, Unreal Engine</li>'+
            '<li>Системы компьютерного зрения с использованием нейросетевых алгоритмов</li></ul>',
            image: './images/gamepad.png'
        },
        {
            title: 'Гибкий подход',
            text: 'Политика компании ARTech заключается в гибком подходе к потребностям Заказчика. Наши специалисты учитывают все пожелания клиента и фиксируют их в техническом задании проекта. Реалзиация проектов ориентирована на подходы Agile, Scrum при разработке и реализации проектов',
            image: './images/map2.png'
        },
        {
            title: 'Техническая поддержка',
            text: 'Специалисты ARTech осуществляют техническую поддержку всех коммерческих проектов и готовы оперативно оказывать всю необходимую помощь Заказчику',
            image: './images/security.png'
        },
    ],
    contacts: {
        title: 'Наши контакты...'
    }
}

const EN = {
    menu: [
        {
            name: 'Areas of activity',
            link: '#activity',
            click: false
        },
        {
            name: 'Projects',
            link: '#projects',
            click: false
        },
        {
            name: 'Services',
            link: '#services',
            click: false
        },
        {
            name: 'Contacts',
            link: '#contacts',
            click: false
        },
        {
            name: 'Sign in',
            link: '/personal/',
            click: false
        },

    ],
    about: {
        text: 'ARTech is a Russian IT company that provides services for the development of system and application software using computer vision technologies, augmented reality, and neural network algorithms'
    },
    activity: [
        {
            title: 'Personal',
            text: 'User Content',
            image: './images/people.png'
        },
        {
            title: 'Industrial',
            text: 'Navigation in the territory of large industries, workshops, pavilions, malls',
            image: './images/factory.png'
        },
        {
            title: 'Entertainment',
            text: 'Creation of exciting games based on the search on the ground, quests with elements of augmented reality',
            image: './images/gamepad.png'
        },
        {
            title: 'Tourism',
            text: 'Routes in cities, tourist areas or inside historical sites (museums, galleries, etc.)',
            image: './images/fly.png'
        },
        {
            title: 'Advertising',
            text: 'Using hidden advertising to get discounts, prizes, etc. for the buyer',
            image: './images/display.png'
        },
    ],
    projects: [
        {
            id: 'oko',
            subtitle: 'oKo Digital Ecosystem Product',
            title: 'oKo-face',
            text: 'oKo-face is a system of personalized keyless access for a person to a certain territory (enterprise; separate premises of an enterprise; parking; house; garage, etc.)',
            images: false,
            link: 'https://ar-tech.tech/okoface/',
            linktext: 'Go to product website',
            html: '<img class="phone" src="/images/oko/1.png"/>'
        },
        {
            id: 'oko-control',
            subtitle: 'Продукт цифровой экосистемы oKo',
            title: 'oKo-control',
            text: 'oKo-control – программное обеспечение для визуального контроля продукции широкого спектра.',
            images: false,
            link: 'https://ar-tech.tech/okocontrol',
            linktext: 'Перейти на сайт продукта',
            html: '<img class="phone" src="/images/oko/1.png"/>'
        },
        {
            id: 'peekid',
            title: 'PEEKID',
            subtitle: 'oKo Digital Ecosystem Product',
            text: "PEEK ID - A system for granting access to a computer using face recognition\n\n\nPEEK ID recognizes whether the user is currently in front of the computer and blocks access if the user is away or not authorized.",
            images: false,
            link: 'http://peekid.org/',
            linktext: 'Go to product website',
            html: '<img class="phone" src="/images/peekid/1.png"/>'
        },
        {
            id: 'okoshop',
            title: 'oKo shop',
            subtitle: 'oKo Digital Ecosystem Product',
            text: 'oKo-shop is a multi-functional service for your site that allows you to sell goods through personal live broadcasts at the request of buyers.',
            images: false,
            link: 'https://ar-tech.tech/okoshop/',
            linktext: 'Go to product website',
            html: '<img class="phone" src="/images/okoshop/1.png"/>'
            //html: '<img class="phone" src="/images/okoshop/1.jpg"/><img class="phone" src="/images/okoshop/2.jpg"/><img class=" phone" src="/images/okoshop/3.jpg"/>'
        },
        {
            id: 'followme',
            title: 'Follow Me',
            subtitle: 'Navigation Product',
            text: 'Follow Me is a navigational mobile application for building routes using augmented reality technology. The application does not use mobile Internet, Wi-Fi, GPS. You can easily share your routes with friends and colleagues.',
            images: [
                '/images/followme/1.jpg',
                '/images/followme/2.jpg',
                '/images/followme/3.jpg'
            ],
            link: 'http://8path.tech/',
            linktext: 'Go to product website',
            html: '<img class="phone" src="/images/followme/1.png"/>'
            //html: '<img class="phone" src="/images/followme/1.jpg"/><img class="phone" src="/images/followme/2.jpg"/><img class=" phone" src="/images/followme/3.jpg"/>'
        },
        {
            id: 'brightalphabet',
            title: 'Bright Alphabet',
            subtitle: 'Educational product',
            text: 'Bright Alphabet is a mobile educational application for preschool children with augmented reality technology. Allows you to learn foreign alphabets in an interactive way.',
            images: [
                '/images/brightalphabet/1.jpg',
            ],
            link: 'https://ar-tech.tech/brightalphabet/',
            linktext: 'Go to product website',
            html: '<img class="phone" src="/images/brightalphabet/1.png"/>'
        },
    ],
    services: [
        {
            title: 'Entertainment AR-content',
            text: 'Games, hidden interactive ads, etc',
            image: './images/gamepad.png'
        },
        {
            title: 'Learning AR-applications',
            text: 'Applications for teaching and examining schoolchildren, students, employees',
            image: './images/student.png'
        },
        {
            title: 'AR-technology-based control and control systems',
            text: 'Remote administration systems, control over the implementation of technological processes',
            image: './images/phone_a_box.png'
        },
        {
            title: 'Intelligent systems based on computer vision',
            text: 'Access control systems to the territory of objects',
            image: './images/face.png'
        },
        {
            title: 'Industrial solutions based on computer vision',
            text: 'Product quality control systems',
            image: './images/eye.png'
        },
    ],
    contacts: {
        title: 'Contacts'
    }
}

const Text = {
	'RU': RU,
	'EN': EN,
}

export default Text;