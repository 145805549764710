<template>
  <div>
    <div class="header">
      <div class="lang">
        <a v-for="(lang, index) in $root.langs" v-bind:class="{ active: $root.LANG == lang }" @click="selectLang(lang);" :key="index">{{lang}}</a>
      </div>
			<img class="logo" src="images/logo.png">
			<div class="menu">
				<!--a class="menu-item" href="#activity">
					Сферы деятельности
				</a>
				<a class="menu-item" href="#projects">
					Проекты
				</a>
				<a class="menu-item" href="#services">
					Услуги
				</a>
				<a class="menu-item" href="#contacts">
					Контакты
				</a-->
        <a v-for="(menu, index) in $root.TEXT.menu" :href="menu.link" :key="index" v-on:click="$emit(menu.click)" class="menu-item">
         {{menu.name}}
        </a>
			</div>
		</div>
    <about/>
    <activity v-bind:header="getHeader('activity')"/>
    <projects v-bind:header="getHeader('projects')" @showFeedback="showFeedback"/>
    <services v-bind:header="getHeader('services')"/>
    <contacts v-bind:header="getHeader('contacts')" @showFeedback="showFeedback"/>
    <feedback v-bind:project="selectProject" v-if="isFeedback" @showFeedback="showFeedback"/>
  </div>
</template>

<script>
import about from './components/about.vue'
import activity from './components/activity.vue'
import contacts from './components/contacts.vue'
import projects from './components/projects.vue'
import services from './components/services.vue'
import feedback from './components/feedback.vue'

import './assets/css/index.css';

export default {
  name: 'App',
  components: {
    about,
    activity,
    contacts,
    projects,
    services,
    feedback
  },
  data() {
    return {
      isFeedback: false
    }
  },
  methods: {
    selectLang: function(lang) {
      this.$root.LANG = lang;
    },
    getHeader: function(comp) {
      this.$root.TEXT.menu
      return this.$root.TEXT.menu.find(item => item.link === '#' + comp).name
    },
    showFeedback: function(selectProject) {
      this.selectProject = selectProject
      if (this.isFeedback)
        this.isFeedback = false
      else
        this.isFeedback = true
    }
  },
}
</script>