<template>
    <div id="feedback">
        <div class="feedback-content">
            <svg @click="$emit('showFeedback')" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
            <h2>Обратная связь</h2>
            <form>
                <div>
                    <label>Как к Вам обращатся</label>
                    <input type="text" v-model="name">
                </div>
                <div>
                    <label>E-mail</label>
                    <input type="text" v-model="email">
                </div>
                <div>
                    <label>Сообщение</label>
                    <textarea v-model="text"></textarea>
                </div>
                <div>
                    <input type="button" @click="send" value="Отправить">
                </div>
            </form>
        </div>
    </div>
</template>


<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                name: '',
                email: '',
                text: this.project.title
            }
        },
        props: ['header', 'showFeedback', 'project'],
        methods: {
            send: function() {
                const params = new URLSearchParams();
                params.append('name', this.name);
                params.append('email', this.email);
                params.append('text', this.text);

                console.log(params)

                axios.post(
                    "/mail.php",
                    params, {
                        headers: {
                            'content-type': 'application/x-www-form-urlencoded'
                        }
                    }

                ).then(function(responce) {
                    console.log('SUCCESS!!');
                    console.log(responce);
                })
                .catch(function() {
                    console.log('FAILURE!!');
                });


            },
        },
    }
</script>