<template>
    <div id="projects">
        <h2>{{header}}</h2>
        <div class="swiper-container">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
                <!-- Slides -->
                <div v-for="(project, index) in $root.TEXT.projects.items" class="swiper-slide" :id="project.id" :key="index">
                    <div>
                        <h3 v-if="project.subtitle">{{project.subtitle}}</h3>
                        <h2>{{project.title}}</h2>
                        <div class="content">
                            <div v-html="project.html"></div>
                            <div>
                            <p>
                                {{project.text}}
                            </p>
                            <!--a style="clear: both;" :href="project.link">
                                {{$root.TEXT.projects.buttontext}}
                            </a-->
                            <a style="clear: both;" href="#projects" @click="$emit('showFeedback', project)">
                                {{$root.TEXT.projects.buttontext}}
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>
</template>

<script>

import Swiper, { Navigation, Pagination } from 'swiper/swiper-bundle.min.js';
Swiper.use([Navigation, Pagination]);
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'

export default {
  props: ['header', 'showFeedback'],
  computed: {
    userscount: function () {

        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://ar-tech.tech/oKo-stream/api.php?action=getuserscount', false);
        xhr.send();
        if (xhr.status != 200) {
            console.log( xhr.status + ': ' + xhr.statusText ); // пример вывода: 404: Not Found
            return 0;
        } else {
            try {
                return JSON.parse(xhr.responseText).count;
            }
            catch(e) {
                return 0;
            }
        }
    }
  },
  mounted() {
    new Swiper('.swiper-container', 
        {
            // Optional parameters
            direction: 'horizontal',
            loop: true,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }
    );
  },
  updated() {
    console.log('update');
    new Swiper('.swiper-container', 
        {
            // Optional parameters
            direction: 'horizontal',
            loop: true,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }
    );
  }
}
</script>