<template>
    <div id="contacts">
        <div>
            <div>
                <img src="images/mail.png" @click="$emit('showFeedback', {})">
                <p>info@ar-tech.tech</p>
            </div>
            <!--div>
                <img src="./../images/phone.png">
                <p></p>
            </div-->
            <div class="info">
                <span>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЭЙАРТЭК" | ARTECH LLC</span>
                <span>ИНН: 7300000540</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default ({
        props: ['header', 'showFeedback'],
    })
</script>