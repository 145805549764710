<template>
    <div id="services">
        <h2>{{header}}</h2>
        <div class="services-items">
            <div v-for="(item, index) in $root.TEXT.services" :key="index" class="services-item">
                <!--img :src="item.image"-->
                <h3>{{item.title}}</h3>
                <p v-html="item.text"></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default ({
        props: ['header'],
    })
</script>