import Vue from 'vue'
import App from './App.vue'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
//import { fab } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(fas)
library.add(far)
//library.add(fab)


Vue.config.productionTip = false
Vue.use(require('vue-cookies'))


import TEXT from './assets/text.js'

const NotFound = { template: '<p>Страница не найдена</p>' }

const routes = {
  '/': App
}

new Vue({
  data: {
    TEXT: TEXT['EN'],
    LANG: 'EN',
    currentRoute: window.location.pathname,
    langs: [
      'RU', 'EN'
    ]
  },
  computed: {
    ViewComponent () {
      return routes[this.currentRoute] || NotFound
    }
  },
  watch: {
    LANG: function (val) {
      this.TEXT = TEXT[val];
      Vue.$cookies.set('lang', val);
    }
  },
  mounted() {
    var language = window.navigator ? (window.navigator.language ||
      window.navigator.systemLanguage ||
      window.navigator.userLanguage) : "EN";
      language = language.substr(0, 2).toUpperCase();

    console.log(language);
    var lang = Vue.$cookies.get('lang'); 
    if (lang) {
      this.LANG = lang;
      this.TEXT = TEXT[lang];
    }
    else {
      this.LANG = language;
      this.TEXT = TEXT[language];
    }
  },
  render (h) { return h(this.ViewComponent) }
}).$mount('#app')
